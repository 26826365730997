<template>
  <footer class=" block md:flex justify-between text-xs py-5 px-14 bg-grey_dark text-white text-center md:text-left">
    <div class="">
      FRENTS 2024 (c) Все права защищены
    </div>
    <div class="block md:flex gap-x-16">
      <div>
        Политика конфиденциальности
      </div>
      <div>
        Публичная оферта
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
footer {
    @media (max-width: 768px) {
        padding-bottom: 70px;
    }
}
</style>
